import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { IMenuTypes } from 'utils/models';

// components
import SideActions from './SideActions';
import Modal from './Modal';
import ModalProductInfo from './modals/ModalProductInfo';

const SideBar: FC = () => {
  const [openProductInfo, setOpenProductInfo] = useState<boolean>(false);

  const links: IMenuTypes[] = [
    {
      label: 'Overview',
      to: '/dashboard'
    },
    {
      label: 'Schedule',
      to: '/schedule'
    },
    {
      label: 'Patients',
      to: '/patient-list'
    }
  ];

  return (
    <>
      <div className="hidden md:block h-full">
        <div
          className={clsx(
            'bg-white h-full relative',
            // !isCollapse && 'w-52'
            'w-36'
          )}
        >
          <div className="pt-5">
            <SideActions actions={links} />
          </div>
        </div>
      </div>

      <Modal
        open={openProductInfo}
        onClose={setOpenProductInfo}
        size="7xl"
        classNameChild="!p-0"
        round="lg"
      >
        <ModalProductInfo showRecentView showSavedProducts />
      </Modal>
    </>
  );
};

export default SideBar;
