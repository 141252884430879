import React, { FC } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  IMenuActions,
  IMenuButton,
  IMenuLink,
  IMenuTypes,
  isIMenuAtag,
  isIMenuButton,
  isIMenuLink,
  isMenuActions
} from 'utils/models';

// components
import Button from './Button';
import MenuAction from './MenuAction';

interface ISideActions {
  actions: IMenuTypes[];
  isCollapse?: boolean;
}

const SideActions: FC<ISideActions> = ({ actions, isCollapse = false }) => {
  return (
    <>
      {actions
        .filter((item: IMenuTypes) => !item.setting)
        .map((item: IMenuTypes, index: number) => (
          <div key={index}>
            {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

            {isIMenuAtag(item) && (
              <a href={item.href} target={item.target} rel="noreferrer">
                {item.icon}
                {item.label}
              </a>
            )}

            {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

            {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

            {item.divider && <div className="my-5 border border-gray-100" />}
          </div>
        ))}

      <div className="absolute bottom-0 w-full">
        {actions
          .filter((item: IMenuTypes) => item.setting)
          .map((item: IMenuTypes, index: number) => (
            <div key={index}>
              {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

              {isIMenuAtag(item) && (
                <a href={item.href} target={item.target} rel="noreferrer">
                  {item.icon}
                  {item.label}
                </a>
              )}

              {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

              {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

              {item.divider && <div className="my-5 border border-gray-100" />}
            </div>
          ))}
      </div>
    </>
  );
};

// MENU ITEMS
interface IMenuActionItems {
  item: IMenuActions;
  isCollapse?: boolean;
}

const MenuActionItems: FC<IMenuActionItems> = ({ item, isCollapse = false }) => {
  return (
    <MenuAction
      placement="auto"
      classNameMain="w-full"
      menuButton={
        <Button
          variant="invisible"
          size="inline"
          rounded={item?.itemRound || 'none'}
          className={clsx(
            'w-full',
            'flex items-center border-l-4 border-transparent',
            'hover:border-white hover:bg-gray-25 hover:shadow-md'
          )}
          classNameButton={clsx(!isCollapse && 'space-x-8', 'py-5 px-8')}
        >
          {item.icon && <div className="text-hazel-solid-green-600">{item.icon}</div>}
          {!isCollapse && (
            <span className="text-md uppercase text-hazel-solid-green-600 font-semibold">
              {item.label}
            </span>
          )}
        </Button>
      }
      menu={item.actions}
    />
  );
};

// NAV LINK
interface INavLink {
  item: IMenuLink;
  isCollapse?: boolean;
}

const NavLink: FC<INavLink> = ({ item, isCollapse = false }) => {
  const location = useLocation();
  const active = location.pathname.indexOf(item.to as string) === 0 || false;

  return (
    <Link
      to={item.to}
      className={clsx(
        active && 'underline underline-offset-8',
        !isCollapse && 'space-x-8',
        'py-5 px-7 flex items-center decoration-2',
        'hover:underline hover:underline-offset-8'
      )}
    >
      {item.icon && (
        <div className={clsx('text-hazel-solid-green-600', active && '!text-hazel-nude-100')}>
          {item.icon}
        </div>
      )}
      {!isCollapse && (
        <span className={clsx('text-hazel-nude-100 text-sm', active && 'font-semibold')}>
          {item.label}
        </span>
      )}
    </Link>
  );
};

// NAV BUTTON
interface INavButton {
  item: IMenuButton;
  isCollapse?: boolean;
}

const NavButton: FC<INavButton> = ({ item, isCollapse = false }) => {
  return (
    <Button
      variant="invisible"
      type={item.type}
      onClick={item.onClick}
      size="inline"
      rounded={item?.itemRound || 'none'}
      className={clsx(
        'w-full',
        'flex items-center border-l-4 border-transparent decoration-2',
        'hover:underline hover:underline-offset-8'
      )}
      classNameButton={clsx(!isCollapse && 'space-x-8', 'py-5 px-7')}
    >
      {item.icon && <div className={clsx('text-hazel-solid-green-600')}>{item.icon}</div>}
      {!isCollapse && <span className="text-sm text-hazel-nude-100">{item.label}</span>}
    </Button>
  );
};

export default SideActions;
