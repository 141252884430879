import { ElementType, ReactElement, ReactNode } from 'react';
import { ClassValue } from 'clsx';
import {
  TButtonRound,
  TButtonSize,
  TOnBoardingSteps,
  TVariant,
  TYesOrNo,
  TDoctorStatus,
  TDays,
  TConsultationStatus,
  TConsultation,
  TPatientStatus,
  TPatientConsultationStatus,
  TBookingSteps,
  TGenders,
  TFileTypes,
  TVisibilityTypes,
  TEscriptsStatus,
  TEscriptTokenStatus,
  TLimits,
  TFollowUpPeriod,
  TClinicalNoteType
} from './types';
import { To } from 'react-router-dom';

export interface IConstantConditions {
  id: number;
  className: ClassValue;
  value: string;
  icon: ElementType;
}

export interface IOnboardingSteps {
  step: TOnBoardingSteps;
  setStep: (x: TOnBoardingSteps) => void;
}

export interface IYesOrNo {
  value: TYesOrNo;
  label: string;
}

export interface IGuard {
  children: ReactElement;
}

export interface IProfile {
  profile_pic: any;
  first_name: string;
  lastName: string;
  registration: string;
  email: string;
  timeZone: string;
  contact_no: string;
  street: string;
  city: string;
  state: string;
  postCode: string;
  verified: boolean;
}
export interface ISelectOptions {
  value: any;
  label: string;
  others?: any;
  disabled?: boolean;
}

export interface IStatusFilter {
  value: string;
  text: string;
  className: string;
}

export interface IBaseActionType {
  title?: ReactNode;
  variant?: TVariant;
  className?: string;
  icon?: ReactNode;
  iconSide?: 'left' | 'right';
  disabled?: boolean;
  size?: TButtonSize;
  round?: TButtonRound;
}

export interface IMySummary {
  month_appointment: number;
  new_message: number;
  total_consultation: number;
  total_patient: number;
  week_appointment: number;
  active_patient: number;
  inactive_patient: number;
}

export interface NotificationArray {
  id: string;
  content: string;
  created_at: string;
  status: string;
  title: string;
}
export interface INotificationType {
  [key: string]: NotificationArray;
}
export interface ITimeOptionType {
  value: string;
  label: string;
  disabled?: boolean;
}
export interface IPatient {
  first_name: string;
  id: string;
  last_name: string;
}
export interface IProduct {
  className: string;
  id: string;
  price: number;
  title: string;
}
export interface IScheduleProps {
  start_at: string;
  end_at: string;
  calendar_day: string;
}
export interface IConsulationsProps {
  id: string;
  doctor_set_date: string;
  duration: number;
  enquiry: string | null;
  status: string;
  patient: IPatient;
  product: IProduct;
  product_id: number;
  schedule: IScheduleProps;
}
export interface DoctorMonthlyAppointmentProps {
  consultation: Array<IConsulationsProps>;
  date: any;
  schedules: Array<IScheduleProps>;
  unavailabilities: any;
}

export interface IProfileEdit {
  profile_pic: any;
  first_name: string;
  last_name: string;
  provider_no: string;
  email: string;
  timezone: string;
  contact_no: string;
  city: string;
  state: string;
  street: string;
  postcode: string;
  description: string;
  phone_verification: boolean;
}

export interface IDoctorProfile {
  doctor_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: Date;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  provider_no: string;
  prescriber_no: string;
  registration_no: string;
  profile_pic: string;
  description: string;
  onboarding_step: TOnBoardingSteps;
}

export interface IButtonAction extends IBaseActionType {
  onClick: (e: any) => void;
  type?: 'button' | 'submit';
  isLoading?: boolean;
}

export interface IMenu {
  // to: To;
  label: string;
  icon?: ReactNode;
  itemRound?: TButtonRound;
  className?: ClassValue;
  divider?: boolean;
  setting?: boolean;
}

export interface IMenuLink extends IMenu {
  to: To;
  state?: { [x: string]: any };
  replace?: boolean;
}

export const isIMenuLink = (action: any): action is IMenuLink => typeof action.to === 'string';

export interface IMenuAtag extends IMenu {
  href: string;
  target?: string;
}

export const isIMenuAtag = (action: any): action is IMenuAtag => typeof action.href === 'string';

export interface IMenuButton extends IMenu {
  onClick: (event: any) => void;
  type?: 'button' | 'submit';
}

export const isIMenuButton = (action: any): action is IMenuButton =>
  typeof action.onClick === 'function';

export interface IMenuActions extends IMenu {
  actions: IMenuTypes[];
  // offset?: number | [number, number];
  hoverable?: boolean;
}

export const isMenuActions = (action: any): action is IMenuActions =>
  action.actions instanceof Array;

export type IMenuTypes = IMenuLink | IMenuButton | IMenuActions;

export interface IConsultationDetails {
  id: string;
  reference_id: number;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  step: TBookingSteps;
  status: TConsultationStatus;
  prescription: IConsultationPrescription;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  documents: IConsultationDocuments[];
  can_finish: boolean;
  notes: string | null;
}

export interface IConsultationHistory {
  consultation_status: TConsultationStatus;
  consultation_ref_id: number;
  consultation_date: Date;
  consultation_type: IConsultationType;
  script: IConsultationDocuments;
  prescription: IPrescriptionDetails;
}

export interface IPatientConsultationHistory {
  id: string;
  reference_id: number;
  doctor: IDoctorDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  status: TConsultationStatus;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  can_finish: boolean;
  notes: string | null;
}

export interface IConsultationDetailsNoPrescription {
  id: string;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  step: TBookingSteps;
  status: TConsultationStatus;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  documents: IConsultationDocuments[];
}

export interface IConsultationDocuments {
  filename: string;
  file_url: string;
  file_key: string;
  file_type: TFileTypes;
  uploaded_by: string;
  upload_date: Date;
  visibility: TVisibilityTypes;
}

export interface IExtendedDocuments extends IConsultationDocuments {
  consultation: IConsultationDetails;
}

export interface ITokenResponse {
  code: string;
  success: boolean;
  message: string;
  token: string;
  refresh_token: string;
  token_expires: string;
  refresh_token_expires: string;
  otpHash: string;
  verification_code: string;
  doctor: IUser;
}
export interface IUserToken {
  id: string;
  status: TDoctorStatus;
}

export interface IUser {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IDoctorProfile;
  phone_verification: boolean;
  email_verification: boolean;
  doctor_type_id: string;
  status: TDoctorStatus;
  doctor_type: IDoctorMeTypes;
}
export interface IMeResponse {
  code: string;
  success: boolean;
  message: string;
  is_from_admin: boolean;
  doctor: IUser;
}

export interface IOtpSendResponse {
  code: string;
  success: boolean;
  message: string;
  fullHash: string;
  verification_code: number;
}

export interface IOtpVerifyResponse {
  code: string;
  success: boolean;
  message: string;
}
export interface ITimeRange {
  start_at: string;
  end_at: string;
}
export interface ISchedule {
  calendar_day: TDays;
  schedules: ITimeRange;
}
export interface IDoctorScheduleResponse {
  code: string;
  success: boolean;
  message: string;
  schedules: ISchedule;
}
export interface IFileCheckResponse {
  success: boolean;
  message: string;
}

export interface IPatientSchedule {
  end_at: string;
  start_at: string;
}
export interface IUpcomingConsultation {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  enquiry: string;
  mobile_no: string;
  patient_id: string;
  profile_pic: string | null;
  start_at: string;
  end_at: string;
}

export interface ITableHeaderProps {
  name: string;
  field: string;
  sortable: boolean;
  customSort: boolean;
  filter: boolean;
  filterVal: Array<IStatusFilter> | null;
  width: string;
}

export interface IPatientProduct {
  title: string;
  className: string;
}
export interface IConsulation {
  type: string | null;
  doctor_set_date: string;
  product: IPatientProduct;
}

export interface IPatientProfile {
  patient_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  pronoun: string;
  gender: TGenders;
  date_of_birth: Date;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  medicare_no: string;
  profile_pic: string;
  is_australian_resident: 1 | 2;
  is_over_18: 1 | 2;
  tried_conventional_treatment: 1 | 2;
  medical_symptoms: string;
  medical_conditions: string;
  conditions: string;
  other_conditions: string;
  how_long_suffered: string;
  using_any_medicine: string;
  have_any_allergy: string;
  allergy_details: string;
  relevant_notes: string;
  about_polln: string;
  currently_being_treated: 1 | 2;
  current_prescription: 1 | 2;
  taking_any_medication: 1 | 2;
  taking_any_medication_list: string;
  achieve_polln: string;
  addtl_info: string;
  patient_notes: string;
  careteam_notes: string;
  careteam_notes_last_edit_at: Date;
  careteam_notes_last_edit_by: string;
  weight: number;
  height: number;
}

export interface IPatientHistory {
  any_other_condition_aware_of_auto_immunity: string;
  any_other_condition_aware_of_cardio: string;
  any_other_condition_aware_of_derma: string;
  any_other_condition_aware_of_gastro: string;
  any_other_condition_aware_of_mental: string;
  any_other_condition_aware_of_metabolic: string;
  any_other_condition_aware_of_muskolo: string;
  any_other_condition_aware_of_nuero: string;
  any_other_condition_aware_of_reproductive: string;
  any_other_condition_aware_of_urinary: string;
  diagnosed_conditions: string;
  do_you_drink: string;
  do_you_smoke: String;
  experiencing_any_pain: string;
  experiencing_any_pain_other: string;
  has_diagnosed_reproductive_condition: string;
  have_allergies: string;
  impact_daily_life_financial: number;
  impact_daily_life_mental: number;
  impact_daily_life_personal: number;
  impact_daily_life_physical: number;
  investigation_surgical_intervention: string;
  investigation_surgical_intervention_other: string;
  investigation_surgical_surgeries: string;
  last_pap_smear: string;
  mens_apperance: string;
  mens_blood_color: string;
  mens_cycle_frequency: string;
  mens_flow_volume: string;
  mens_pain_level: string;
  mens_period_last: string;
  natural_therapies_alternatives: string;
  other_notes: string;
  seeing_any_professional: string;
  seen_practitioner: string;
  seen_practitioner_others: string;
  status: 'PENDING' | 'DONE';
  symptoms: string;
  taking_any_medication: string;
  practitioner_contact_details: string;
  surgeries_others: string;
  medication_list: string;
}

export interface IPatientBackground {
  approach_your_health: string;
  diagnosed_health_condition: string;
  diagnosed_health_condition_others: string;
  gastro: string;
  experiencing_any_pain: string;
  experiencing_any_pain_other: string;
  health_goals: string;
  health_goals_others: string;
  hear_about: string;
  interested_speciality: string;
  menopausal: string;
  menstruation: string;
  mood: string;
  other: string;
  other_symptoms: string;
  pain: string;
  sexual_health: string;
  undiagnosed_health_condition: string;
  vaginal_health: string;
  virtual_not_appropriate_reason: string;
  what_brings_you_to_hazel: string;
  whatelse_interested: string;
  whatelse_interested_others: string;
}
export interface IPatientData {
  id: string | number;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IPatientProfile | null;
  phone_verification: boolean;
  email_verification: Boolean;
  status: TDoctorStatus;
  consultation_status: TConsultationStatus;
  last_consultation_date: string;
}

export interface ITableProps {
  contactNo: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  patientId: number;
  profilePic: string;
  status: string;
  time: string;
}
export interface ICalendarConsulation {
  date: string; // or Date?
  enquiry: string;
  id: string;
  setDate: string; // or Date?
  timeEnd: string; // or Date?
  timeStart: string; // or Date?
  title: string;
  type: TConsultation;
}

export interface IHistoryProduct {
  title: string;
  className: string;
}
export interface IFileUploads {
  file_url: string;
  filename: string;
}
export interface IAppointmentHistory {
  id: string;
  enquiry: string | null;
  doctor_set_date: number | Date | any;
  product: IHistoryProduct | null;
  script_uploads: Array<IFileUploads> | null;
  tga_status: string;
  tga_uploads: Array<IFileUploads> | null;
}

export interface IAddress {
  street: string;
  city: string;
  state: string;
  postcode: string;
}

export interface IPatientResponse {
  code: string;
  message: string;
  patient: IPatientData | null;
}

export interface ITimes {
  id?: string;
  daysOfWeek?: string;
  timeStart: string;
  timeEnd: string;
}

export interface IDaysOfWeek {
  [day: string]: ITimes[];
}

export interface IInputSchedules {
  id?: string;
  start_at: Date;
  end_at: Date;
}

export interface IScheduleDetails {
  id: string;
  recurring_id: string | null;
  date: Date[];
  start_at: Date;
  end_at: Date;
  status: TConsultationStatus | null;
}

export interface IConsultationsResponse {
  code: string;
  message: string;
  success: boolean;
  consultations: IUpcomingConsultation[];
}

export interface IConsultationType {
  id: string;
  name: string;
  color: string;
  duration: number;
  is_default: boolean;
  price: string;
  capitalize_name: TConsultation;
  default_clinical_notes: TClinicalNoteType[];
}

export interface IDoctorDetails {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IDoctorProfile;
  doctor_type: {
    description: string;
  };
}

export interface IPatientDetails {
  id: string;
  dispensing_interval: number;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IPatientProfile;
  health_history: IPatientHistory;
  background: IPatientBackground;
  billing_address: string;
  delivery_address: string;
  phone_verification: boolean;
  email_verification: boolean;
  status: TPatientStatus;
  recent_consultation_id?: string;
  recent_reference_id?: number;
  last_consultation_date_end?: Date;
  consultation_status: TPatientConsultationStatus;
  last_consultation_date: Date | null;
  consent_approved: boolean;
  same_delivery: boolean;
  same_billing: boolean;
  pinned_alert: string | null;
}

export interface IConsultationUpComingDetails {
  id: string;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
}

export interface IProducts {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  product_image: string;
  product_type: IProductType;
  stock: number;
  format: string;
  schedule: string;
  price: string;
  is_active: boolean;
}

export interface IProductType {
  dispensing_limit: number;
  icon: string;
  id: string;
  name: string;
  unit: string;
}

export interface ISupplements {
  id: string;
  sku: string;
  brand: string;
  name: string;
  image_url: string;
  stock: number;
  stock_last_updated: Date;
  price: string;
  status: string;
}

export interface IProductSelection {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route?: string;
  product?: IProducts;
}

export interface IProductVoid {
  script_product_id: string;
  reason: string;
}

export interface IRecommendationVoid {
  script_id: string;
  doctor?: string;
  reason: string;
}

export interface IPrescriptionNoteDetails {
  notes: string;
  note_at: Date;
}

export interface ISupplementVoid {
  script_supplement_id: string;
  reason: string;
}

export interface ILifestyleVoid {
  script_lifestyle_id: string;
  reason: string;
}

export interface IReferralVoid {
  script_referral_id: string;
  reason: string;
}

export interface IPatientLifestyle {
  advice: string;
  duration: string;
  frequency: string;
  other_advice?: string;
}

export interface IPatientSupplement {
  id: string;
  dosage: string;
  prescription_id?: string;
  supplement?: ISupplements;
  supplement_id?: string;
  supplement_image?: string;
}

export interface IPatientReferral {
  type: string;
  other_type?: string;
  timeframe: string;
  other_timeframe?: string;
  option_document?: string;
  document?: any;
}

export interface IProductCustom {
  id?: string;
  product_name: string;
  product_details: string;
  product_type: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  brand_name: string;
  sku: string;
  type: string;
  format: string;
  frequency: string;
  route: string;
}

export interface IConsultationPrescription {
  id: string;
  consultation_id: string;
  custom_products: IProductCustom[];
  consultation_notes: string;
  careteam_notes: string;
  // notes_history: IConsultationPrescriptionNote[];
  products: IProductSelection[];
  supplements: IPatientSupplement[];
  lifestyles: IPatientLifestyle[];
  referrals: IPatientReferral[];
  naturophaty_recommendation: string;
  mental_recommendation: string;
  dietician_recommendation: string;
  sexual_recommendation: string;
  lifestyle_recommendation: string;
  patient_notes: string;
  clinical_notes: IConsultationClinicalNotes[];
  dispensing_interval_reason: string;
  dispensing_interval: number;
}

export interface IConsultationClinicalNotes {
  note: string;
  type: TClinicalNoteType;
  isDefault?: boolean;
}

export interface IConsultationPrescriptionNote {
  history: string;
  current_condition: string;
  previous_therapy: string;
  menstruation: string;
  energy: string;
  created_at: Date;
}

export interface IPrescriptionDetails {
  id: string;
  patient_id: string;
  consultation_id: string;
  products: IProductSelection[];
  custom_products: IProductCustom[];
  consultation: IConsultationDetails;
  consultation_notes: string;
  treatment_notes: string;
  careteam_notes: string;
  followup_period: TFollowUpPeriod;
  followup_value: number;
  set_followup: boolean;
  with_escript: boolean;
  dispensing_interval: number;
  dispensing_interval_reason: string;
  reference_id: number;
}

export interface IFileMutation {
  id?: number;
  filename: string;
  file_url: string;
  filetype?: TFileTypes;
  file_visibililty?: 'ALL' | 'RESTRICTED';
}

export interface IEscripts {
  id: string;
  patient: IPatientDetails;
  doctor: IDoctorDetails;
  consultation_date: Date;
  product: IProducts;
  description: string;
  reference_id: number;
  remaining: number;
  total: number;
  status: TEscriptsStatus;
  tokens: IEscriptsToken[];
}

export interface IEscriptsToken {
  id: string;
  doctor_name: string;
  source_ref: string;
  source_time: Date;
  expiry: Date;
  status: TEscriptTokenStatus;
}

export interface IFormOtpLogin {
  email: string;
  password: string;
  doctorId: string;
  otpHash: string;
  verificationCode: string;
  onClose?: () => void;
}

export interface ICurrentMedication {
  name: string;
  dose: string;
  frequency: string;
  reason: string;
}

export interface IMedicineDetails {
  type: string;
  frequency: string;
}

export interface IConsultationSummary {
  available: number;
  booked: number;
  percentage: number;
}

export interface IDispensingLimit {
  dispensing_limit: number;
  icon: TLimits;
  id: string;
  name: string;
  unit: string;
  svg: string;
}

export interface IHistoryConsultationNotes {
  reference_id: number;
  start_at: Date;
  end_at: Date;
  notes: string;
  // prescription: IConsultationNotes;
}

export interface IConsultationNotes {
  careteam_notes: string;
  consultation_notes: string;
  treatment_notes: string;
}

export interface IDoctorDateSchedule {
  id?: string | null;
  start_at: Date;
  end_at: Date;
  recurring_id?: string | null;
}

export interface ICreateSchedule {
  days: string[];
  start_at: Date;
  end_at: Date;
  recurring_id?: string;
}

export interface IProductDetails {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  product_image: string;
  description: string;
  stock: number;
  format: string;
  schedule: string;
  category: string;
  size: string;
  size_unit: string;
  weight: string;
  price: string;
  thc: string;
  cbd: string;
  is_thc: boolean;
  is_cbd: boolean;
  is_high_cbd: boolean;
  is_high_thc: boolean;
  is_active: boolean;
  updated_at: Date;
  product_type: {
    dispensing_limit: number;
    unit: string;
    icon: string;
  };
  cultivar: string;
  strength: string;
}

export interface INotifications {
  content: string;
  id: string;
  is_read: boolean;
  link: string;
  title: string;
  created_at: string;
}

export interface IDoctorNotifications {
  notifications: INotifications[];
}

export interface IHandleNotification {
  id: string;
  is_read: boolean;
  link: string;
}

export interface IDispensingLimitHistory {
  clinical_notes: string;
  created_at: Date;
  doctor: IDoctorDetails;
  new_dispensing_limit: number;
  old_dispensing_limit: number;
  product_type: IDispensingLimit;
}

export interface INoteDetails {
  patientName: string;
  consultationDate: Date;
  careTeamNotes: string;
  careTeamEditedBy: string;
  careTeamLastEdit: Date;
  consultationNotes: string;
  consultationId: string;
}

export interface IPatientDetailsLocationState {
  tab: 'consult' | 'document' | 'escript';
  id?: string;
}

export interface IEscriptPrescription {
  consultation_date: string;
  prescription_id: string;
  product_id: string;
  product: IProducts;
  dosage: string;
  frequency: string;
  no_of_units: string;
  repeats: string;
  route: string;
  remaining: number;
  doctor: {
    first_name: string;
    last_name: string;
    profile_pic: string;
  };
  created_at: string;
}

export interface IEscriptSupplement {
  dosage: string;
  prescription_id: string;
  supplement_id: string;
  supplement: ISupplements;
  remaining: number;
  doctor: {
    first_name: string;
    last_name: string;
    profile_pic: string;
  };
  created_at: string;
}

export interface IEscriptLifestyle {
  prescription_id: string;
  advice: string;
  duration: string;
  frequency: string;
}

export interface IEscriptReferrals {
  prescription_id: string;
  type: string;
  timeframe: string;
}

export interface ITreatmentPlan {
  type: string;
  name: string;
  frequency: string;
  consultation_date: string;
  prescribed_by: string;
  repeat: number;
  image: string;
}

export interface ILifeStyle {
  image: React.ReactNode;
  recommendation: string;
  frequency: string;
}

export interface IEscriptNaturophaty {
  naturophaty_recommendation: string;
  doctor: IEscriptDoctor;
}

export interface IEscriptDietitian {
  dietician_recommendation: string;
  doctor: IEscriptDoctor;
}

export interface IEscriptMental {
  mental_recommendation: string;
  doctor: IEscriptDoctor;
}

export interface IEscriptSexual {
  sexual_recommendation: string;
  doctor: IEscriptDoctor;
}

export interface IEscriptDoctor {
  first_name: string;
  last_name: string;
}

// to change later
export interface IPatientProductExisting {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route?: string;
  product?: string;
  doctor?: string;
  date: string;
}

export interface IPatientSupplementExisting {
  id?: string;
  dosage: string;
  prescription_id?: string;
  supplement?: string;
  supplement_id?: string;
  supplement_image?: string;
  doctor?: string;
  date: string;
}

export interface IPatientLifestyleExisting {
  advice: string;
  duration: string;
  frequency: string;
  doctor?: string;
  date: string;
}

export interface IPatientReferralExisting {
  referral_type: string;
  timeframe: string;
  doctor?: string;
  date: string;
}

export interface IDoctorTypes {
  description: string;
  id: string;
}

export interface IDoctorMeTypes {
  description: string;
  has_lifestyle: boolean;
  has_mental: boolean;
  has_nutrition: boolean;
  has_prescriptions: boolean;
  has_referrals: boolean;
  has_sexual: boolean;
  has_supplements: boolean;
  has_naturopathy: boolean;
  id: string;
}

export interface INoteItem {
  note: string;
  type: string;
  date: string;
}

export interface IMedicationProps {
  dosage: string;
  frequency: string;
  name: string;
  reason: string;
}

export interface ICarePlanDoctor {
  first_name: string;
  last_name: string;
  profile_pic: string;
}

export interface ICarePlanRecommendation {
  created_at: Date;
  doctor: ICarePlanDoctor;
  recommendation: string;
  script_id: string;
}

export interface ICarePlanLifestyle {
  advice: string;
  duration: string;
  frequency: string;
  other_advice: string;
  prescription_id: string;
}

export interface ICarePlanReferral {
  prescription_id: string;
  timeframe: string;
  type: string;
  other_type?: string;
  other_timeframe?: string;
  document?: string;
}

export interface ICarePlanResponse extends IQueryResponse {
  data: {
    notes: ICarePlanRecommendation[];
    clinical_notes: ICarePlanClinicalNotes[];
    products: IEscriptPrescription[];
    supplements: IEscriptSupplement[];
    referrals: ICarePlanReferral[];
    naturophaty_recommendation: ICarePlanRecommendation[];
    dietician_recommendation: ICarePlanRecommendation[];
    lifestyle_recommendation: ICarePlanRecommendation[];
    mental_recommendation: ICarePlanRecommendation[];
    sexual_recommendation: ICarePlanRecommendation[];
  };
}
export interface ICarePlanClinicalNotes {
  notes: {
    created_at: Date;
    doctor: {
      first_name: string;
      last_name: string;
      profile_pic: string;
    };
    note: string;
    script_id: string;
  }[];
  type: TClinicalNoteType;
}

export interface IQueryResponse {
  code: string;
  message: string;
  success: boolean;
}

export interface IEscriptPrescription {
  product_id: string;
  product: IProducts;
  dosage: string;
  frequency: string;
  no_of_units: string;
  repeats: string;
}

export interface IEscriptSupplement {
  dosage: string;
  prescription_id: string;
  supplement_id: string;
  remaining: number;
}

export interface IMeetingArgs {
  status: string;
  consultationDetails: IConsultationDetails;
  handleEndSession: () => Promise<any>;
}
